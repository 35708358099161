
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../../../Type/Type.module.scss';

.FieldQuestion {
  @extend .base-large;

  margin-bottom: spacing(2);
  display: inline-block;
  color: $color-primary-grey;
  &.dark {
    
      label {
 color: white !important;
      
    }
   
  }
}
